import React, { useState } from "react";
import TopNavbar from "../navigator/NavBar";
import { useHistory } from "react-router-dom"

const AddForm = () => {
  const[name, setName] = useState('');
  const[description, setDescription] = useState('');
  const[rielmoney, setRielmoney] = useState('');
  const[dollarmoney, setDollarmoney] = useState('');
  const[isPending, setIsPending] = useState(false);

  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    const res = {
      name,
      description,
      rielmoney,
      dollarmoney,
    }

    setIsPending(true);
    fetch('https://weddinglaravelapi.herokuapp.com/api/customer/store', {
      method: 'POST',
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(res)
    }).then(() => {
      console.log('new customer added');
      setIsPending(false);
      history.push('/home');

    });
  }
    const style = {
      email: {
        paddingTop: "10px",
        color: "black",
        textAlign: "left",
      },
      button: {
        paddingBottom: "30px",
        color: "black",
        textAlign: "left",
      },

      password: {
        paddingTop: "10px",
        color: "black",
        textAlign: "left",
      },

      btnSubmit: {
        padding: "10px",
        marginTop: "20px",
      },

      para1: {
        paddingTop: "20px",
        fontSize: "30px",
        fontWeight: "bold",
      },
      label: {
        color: "red",
      },
      p: {
        color: "grey",
      },
    }

  return (
      <>
        <TopNavbar />
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-5">
              <p style={style.para1}>កន្លែងបញ្ចូលព័ត៌មានភ្ញៀវ</p>
              <p>ឈ្មោះគណនីចូលប្រើប្រាស់: wedding@gmail.com | លេខសម្ងាត់: 12345678</p>
              <form onSubmit={handleSubmit}>
                <div className="form-group" style={style.email}>
                  <label>
                    ឈ្មោះភ្ញៀវ
                  </label>
                  <input
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="form-control"
                    type="text"
                    placeholder="ឈ្មោះភ្ញៀវ"
                    required={true}
                  />
                </div>
                <div className="form-group" style={style.password}>
                  <label>ព័ត៌មានអំពីភ្ញៀវ</label>
                  <p style={style.p}>ឧទាហរណ៍៖ បងប្អូន មិត្តភក្តិ និងផ្សេងៗជាដើម...</p>
                  <input
                    name="description"
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                    className="form-control"
                    type="text"
                    placeholder="ព័ត៌មានអំពីភ្ញៀវ"
                  />
                  <div className="form-group" style={style.email}>
                    <label>លុយរៀល  --  </label>
                    <label style={style.label}>សូមវាយបញ្ចូលចំនួនទឹកប្រាក់ជា (៛)</label>
                    <p style={style.p}>ចំណាំ៖ ត្រូវវាយបញ្ចូលលេខ 0 នៅពេលគ្មានចំនួនទឹកប្រាក់បញ្ចូល រៀល(៛)</p>
                    <input
                      name="rielmoney"
                      onChange={(e) => setRielmoney(e.target.value)}
                      value={rielmoney}
                      className="form-control"
                      type="number"
                      placeholder="ចំនួនទឹកប្រាក់ (៛)"
                      required={true}
                    />
                  </div>
                  <div className="form-group" style={style.email}>
                    <label>លុយដុល្លារ  --  </label>
                    <label style={style.label}>សូមវាយបញ្ចូលចំនួនទឹកប្រាក់ជា ($)</label>
                    <p style={style.p}>ចំណាំ៖ ត្រូវវាយបញ្ចូលលេខ 0 នៅពេលគ្មានចំនួនទឹកប្រាក់បញ្ចូល ដុល្លារ($)</p>
                    <input
                      name="dollarmoney"
                      onChange={(e) => setDollarmoney(e.target.value)}
                      value={dollarmoney}
                      className="form-control"
                      type="number"
                      placeholder="ចំនួនទឹកប្រាក់ ($)"
                      required={true}
                    />
                  </div>
                </div>
               <div className="form-group" style={style.button}>
                 {
                     !isPending && <button
                         type="submit"
                         style={style.btnSubmit}
                         className="btn btn-primary"
                     >
                       បញ្ចូលព័ត៌មាន
                     </button>
                 }
                 {
                     isPending && <button disabled
                                          type="submit"
                                          style={style.btnSubmit}
                                          className="btn btn-primary"
                     >
                       កំពុងបញ្ចូលព័ត៌មានភ្ញៀវ...
                     </button>
                 }
               </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
}
export default AddForm;
