import React from "react";
import app from "../../server/firebase";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import Container from "react-bootstrap/Container";

const TopNavbar = () => {
  const style = {
    NavTitle: {
      fontSize: "25px",
      fontWeight: "bold",
      color: "black",
      fontFamily: "sans-serif",
    },

    AvatarUser: {
      paddingLeft: "20px",
    },

    NavBar: {
      display: "flex",
      padding: "0.5em",
      justifyContent: "Right",
      alignItems: "center",
      textDecoration: "none",
    },
    NavLink: {
      display: "flex",
      padding: "0.5em",
      justifyContent: "Right",
      alignItems: "center",
      textDecoration: "none",
    },
  };

  return (
    <>
      <Navbar bg="light" variant="light" sticky="top">
        <Container>
          <NavLink to="/home" style={style.NavTitle}>
            កម្មវិធីអាពាហ៍ពិពាហ៍
          </NavLink>
          <Nav className="ml-auto" style={style.NavLinkBar}>
            <NavLink to="/home" activeStyle style={style.NavLink}>
              ទំព័រដើម
            </NavLink>
            {/*<NavLink to="/help" activeStyle style={style.NavLink}>*/}
            {/*  ជំនួយ*/}
            {/*</NavLink>*/}
            {/*<NavLink to="/about" activeStyle style={style.NavLink}>*/}
            {/*  ពត័មានផ្សេងៗ*/}
            {/*</NavLink>*/}
            <NavLink to="/addform" activeStyle style={style.NavLink}>
              បញ្វូលពត័មាន
            </NavLink>
            <NavLink to="/loginform" activeStyle style={style.NavLink}>
              <button
                onClick={() => app.auth().signOut()}
                className="btn btn-primary"
              >
                ចាកចេញ
              </button>
            </NavLink>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default TopNavbar;
