import * as firebase from "firebase/app";
import "firebase/auth";

const app = firebase.initializeApp({
  apiKey: "AIzaSyBLrcdG5FYaCq4GH_-gHgEwcKznPjp0L1A",
  authDomain: "wedding-note-76a23.firebaseapp.com",
  projectId: "wedding-note-76a23",
  storageBucket: "wedding-note-76a23.appspot.com",
  messagingSenderId: "498128436593",
  appId: "1:498128436593:web:454db5ade8f38eef140ad0"
});

export default app;