import React from "react";
import "./App.css";
import ReactDOM from "react-dom";
import { Redirect } from "react-router-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LoginForm from "./components/auth/LoginForm";
import SignUp from "./components/auth/SignUp";
import { AuthProvider } from "./components/auth/Auth";
import PrivateRoute from "./routes/PrivateRoute";
import HomePage from "./module/HomePage";
import AddForm from "./module/form/AddForm";
import HelpPage from "./module/HelpPage";
import AboutPage from "./module/AboutPage";

export default function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <div className="content">
            <Switch>
              <PrivateRoute exact path="/" component={HomePage} />
              <Route path="/loginform" component={LoginForm} />
              <Route path='/home' component={HomePage} />
              <Route path='/addform' component={AddForm} />
              <Route path='/help' component={HelpPage} />
              <Route path='/about' component={AboutPage} />
              <Route exact path="/signup" component={SignUp} />
            </Switch>
            <Redirect to={"/loginform"} />
          </div>
        </div>
      </Router>
    </AuthProvider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));

