import React from "react";
import { Empty, Row, Col } from "antd";
import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.min.css";
import TopNavbar from "./navigator/NavBar";

const HelpPage = () => {
  const style = {
    p: {
      fontSize: "20px",
    },
    SpaceTop: {
      paddingTop: "25px",
    },
    Empty: {
      paddingTop: "50px",
    },
  };

  return (
    <>
    <TopNavbar />
      <Row className="row" style={style.SpaceTop}>
        <Col span={24}>
          <div>
           <p style={style.p}>ជំនួយ</p>
           <p>
               សម្រាប់ទៅលើការប្រើប្រាស់គេហ័ទំព័រនេះសម្រាប់តែយើងជាម្ចាស់កម្មវិធីតែប៉ុណ្ណោះ
           </p>
           <Empty />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default HelpPage;
