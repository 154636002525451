import React from "react";

class ViewData extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      DataisLoaded: false,
    };
  }

  componentDidMount() {
    fetch("https://weddinglaravelapi.herokuapp.com/api/customer")
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          items: json,
          DataisLoaded: true,
        });
      });
  }

  render() {

    const { DataisLoaded, items } = this.state;
    if (!DataisLoaded)
      return (
        <div>
          <h4>កំពុងទាញទិន្នន័យ...</h4>
        </div>
      );

    const style = {
      td: {
        textAlign: "left",
        fontSize: "15px",
      },
      td1: {
        textAlign: "right",
        fontSize: "15px",
      },
      thTop: {
        textAlign: "left",
        fontSize: "15px",
      },
      thTop1: {
        textAlign: "right",
        fontSize: "15px",
      },
      button: {
        textAlign: "center",
        alignItems: "space-between",
      }
    };


    return (
      <div className="App">
        <div className="con"></div>
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th style={style.thTop}>ឈ្មោះភ្ញៀវ</th>
              <th style={style.thTop}>ព័ត៌មានផ្សេងៗ</th>
              <th style={style.thTop1}>លុយរៀល</th>
              <th style={style.thTop1}>លុយដុល្លារ</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => (
              <tr key={item.id}>
                <td style={style.td}>{item.name}</td>
                <td style={style.td}> {item.description}</td>
                <td style={style.td1}>{item.rielmoney} ៛</td>
                <td style={style.td1}>{item.dollarmoney} $</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
export default ViewData;
