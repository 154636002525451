import React, { useCallback, useContext } from "react";
import { withRouter, Redirect } from "react-router";
import "bootstrap/dist/css/bootstrap.min.css";
import app from "../../server/firebase.js";
import { AuthContext } from "./Auth.js";


const LoginForm = ({ history }) => {
  const handleLogin = useCallback(
    async (event) => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      try {
        await app
          .auth()
          .signInWithEmailAndPassword(email.value, password.value);
        history.push("/");
      } catch (error) {
        alert("សូមវាយបញ្ចូលអ៊ីមែលរបស់អ្នក", error);
      }
    },
    [history]
  );

  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Redirect to="/" />;
    
  }

  const style = {
    email: {
      color: "black",
      textAlign: "left",
    },

    password: {
      color: "black",
      textAlign: "left",
    },

    btnSubmit: {
      padding: "10px",
      marginTop: "20px",
    },

    para1: {
      paddingTop: "20px",
      fontSize: "20px",
      fontWeight: "bold",
    },
  };

  return (
    <div className="App">
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-md-5">
            <p style={style.para1}>ចូលប្រើប្រាស់គណនី</p>

            <form onSubmit={handleLogin}>
              <div className="form-group" style={style.email}>
                <label>អ៊ីមែ៉លអ្នកប្រើប្រាស់</label>
                <input
                  name="email"
                  className="form-control"
                  type="email"
                  placeholder="អ៊ីមែ៉លរបស់អ្នក"
                />
              </div>
              <div className="form-group" style={style.password}>
                <label>លេខសម្ងាត់</label>
                <input
                  name="password"
                  className="form-control"
                  type="password"
                  placeholder="លេខសម្ងាត់"
                />
                <button
                  style={style.btnSubmit}
                  type="submit"
                  className="btn btn-primary"
                >
                  ចូលគណនី
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(LoginForm);
