import React, { Component } from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.min.css";
import TopNavbar from "./navigator/NavBar";
import ViewData from "./ViewData";

class HomePage extends Component {
  
  render() {
    const style = {
      p1: {
        fontSize: "20px",
      },
      SpaceTop: {
        paddingTop: "25px",
      },
      Empty: {
        paddingTop: "50px",
      },
      h4: {
        paddingTop: "10px",
        color: "black",
        textAlign: "left",
      },
      p2: {
        paddingBottom: "10px",
      },
    };

    return (
      <>
        <TopNavbar />
        <div className="container">
          <Row className="row" style={style.SpaceTop}>
            <Col span={24}>
              <div>
                <p style={style.p1}>ស្វាគមន៍ទំព័រដើម</p>
                <p style={style.p2}>
                  សម្រាប់ទៅលើការប្រើប្រាស់គេហ័ទំព័រនេះសម្រាប់តែយើងជាម្ចាស់កម្មវិធីតែប៉ុណ្ណោះ
                </p>
              </div>
            </Col>
          </Row>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 style={style.h4}>
                    {" "}
                    តារាងព័ត៌មានភ្ញៀវ
                    <Link to={"addform"} className="btn btn-primary float-end">
                      បញ្ចូលព័ត៌មាន
                    </Link>
                  </h5>
                </div>
                <div className="card-body">
                  <ViewData />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HomePage;
